import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import ServicesOverview from '../components/ServicesOverview/ServicesOverview'
import PortfolioTeaser from '../components/PortfolioTeaser/PortfolioTeaser'
import ContactBoxFullWidth from '../components/ContactBoxFullWidth/ContactBoxFullWidth'
import ImgBenefits from '../images/svgs/benefits.svg'
import stageHome from '../images/svgs/stage_home.svg'

const StartPage = ({ data }) => {
  const { slug, titleStage, titleIntrotext, titleAboutTeasertext, titleBenefits} = data.contentfulFrontpage
  const content_shortIntrotext = data.contentfulFrontpage.childContentfulFrontpageShortIntrotextTextNode.shortIntrotext
  const content_shortAboutTeasertext = data.contentfulFrontpage.childContentfulFrontpageShortAboutTeasertextTextNode.shortAboutTeasertext
  const content_bulletpointsBenefits = data.contentfulFrontpage.childContentfulFrontpageBulletpointsBenefitsRichTextNode.json


  return (
    <Layout>
      <SEO title={titleStage} />
      <Header titleBig={titleStage} color='darkgrey' textcolor='text-white' className='stage-home' bgimg={<stageHome />}/>
      <div className='flex w-full xs:my-16 lg:my-32 px-6 md:px-16 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap'>
          <div className='flex flex-col xs:order-2 md:order-1 pr-8 lg:pr-32 md:w-8/12 max-w-4xl '>
            <p className='leading-relaxed'>{content_shortIntrotext}</p>
          </div>
          <div className='flex flex-col xs:order-1 md:order-2 md:w-4/12 max-w-4xl '>
            <h2 className='leading-relaxed'>{titleIntrotext}</h2>
          </div>
        </div>
      </div>
      <div className='flex w-full xs:my-16 lg:my-4 xs:px-2 sm:px-8 md:px-8 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap justify-center'>
        <ServicesOverview />
        </div>
      </div>
      <div className='flex w-full xs:my-8 lg:mt-40 lg:mb-16 px-6 md:px-16 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap justify-center'>
          <div className='flex flex-col w-full'>
            <p className='category-title'>Über uns</p>
          </div>
          <div className='flex flex-col md:w-6/12 max-w-4xl pr-8 lg:pr-32'>
            <h2 className='leading-relaxed'>{titleAboutTeasertext}</h2>
          </div>
          <div className='flex flex-col md:w-6/12 max-w-4xl '>
            <p className='leading-relaxed'>{content_shortAboutTeasertext}</p>
          </div>
        </div>
      </div>
      <div className='flex w-full xs:my-8 lg:my-16 px-6 md:px-16 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap justify-center'>
          <div className='flex flex-col xs:order-2 md:order-1 md:w-6/12 max-w-4xl -mt-8'>
            <p className='leading-relaxed'><ImgBenefits/></p>
          </div>
          <div className='flex flex-col xs:order-1 md:order-2 md:w-6/12 max-w-4xl '>
            <p className='category-title'>Benefits</p>
            <h2 className='leading-relaxed'>{titleBenefits}</h2>
            <p className='leading-relaxed'>{documentToReactComponents(content_bulletpointsBenefits)}</p>
          </div>
        </div>
      </div>

      <div className='flex w-full flex-wrap xs:my-16 lg:my-16 px-0 justify-center '>
        <ContactBoxFullWidth />
      </div>

      <div className='flex w-full flex-wrap xs:my-16 lg:my-16 px-0 justify-center '>
        <div className='flex w-full text-center'>
          <span className='category-title mx-auto text-center'>Referenzen</span>
        </div>
        <div className='flex w-full text-center'>
          <h2 className='leading-relaxed mx-auto text-center'>Aktuelle Projekte</h2>
        </div>
        <div className='flex w-full mt-32 flex-wrap justify-center'>
          <PortfolioTeaser />
        </div>
      </div>
    </Layout>
  )
}

export default StartPage

export const FrontpageQuery = graphql`
query {
  contentfulFrontpage(slug: {eq: "start"}) {
    slug
    titleStage
    titleIntrotext
    titleAboutTeasertext
    childContentfulFrontpageShortIntrotextTextNode {
      shortIntrotext
    }
    childContentfulFrontpageShortAboutTeasertextTextNode {
      shortAboutTeasertext
    }
    titleBenefits
    childContentfulFrontpageBulletpointsBenefitsRichTextNode {
      json
    }
  }
}

`

